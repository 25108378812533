<template>
  <div class="container_con">
    <div style="padding-top:58px">
      <el-steps :active="active" align-center>
        <el-step title="" description="基本信息"></el-step>
        <el-step title="" description="营业执照"></el-step>
        <el-step title="" description="医疗机构执业许可证"></el-step>
        <el-step title="" description="医疗器械经营许可证"></el-step>
        <el-step title="" description="第二类医疗器械经营 备案凭证"></el-step>
      </el-steps>
    </div>
    <!-- 基本信息 -->
    <div class="el_form" v-if="active == 1">
      <el-form ref="basicForm" :rules="basicFormrules" :model="basicForm" label-width="120px">
        <el-form-item label="客商名称：" prop="merchant_name">
          <el-input
            v-model="basicForm.merchant_name"
            placeholder="请输入客商名称" @blur="input_blur"
            style="width:300px"
          />
        </el-form-item>
        <el-form-item label="助记码：" prop="mnemonic_code">
          <el-input
            v-model="basicForm.mnemonic_code" 
            placeholder="助记码"
            style="width:300px"
          />
        </el-form-item>
        <el-form-item :label="!id?'客商编号前缀：':'客商编号：'" prop="merchant_no">
            <el-select v-model="basicForm.merchant_no" v-if="!id" placeholder="请选择"
                style="width: 300px; margin-right: 15px;" filterable>
                <el-option :label="item.value+'__'+item.title" v-for="(item,index) in zjlist"
                    :key="index" :value="item.value"></el-option>
            </el-select>
            <el-input v-if="id" disabled style="width: 300px;" v-model="basicForm.merchant_no"
                placeholder="请输入客商编号" />
        </el-form-item>
        <el-form-item label="是否销售：" prop="is_sales">
          <el-radio-group v-model="basicForm.is_sales">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="是否进货：" prop="is_purchase">
          <el-radio-group v-model="basicForm.is_purchase">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
    </div>
    <!-- 营业执照 -->
    <div class="el_form" v-if="active == 2">
        
      <el-form ref="busForm" :rules="busFormrules" :model="busForm" label-width="160px">
        <el-form-item label="统一社会信用代码：" prop="bl_code">
          <el-input
            v-model="busForm.bl_code"
            placeholder="请输入统一社会信用代码"
            style="width:300px"
          />
        </el-form-item>
        <el-form-item label="名称：" prop="bl_name">
          <el-input
            v-model="busForm.bl_name"
            placeholder="请输入名称"
            style="width:300px"
          />
        </el-form-item>
        <el-form-item label="法定代表人：" prop="bl_legal_person">
          <el-input
            v-model="busForm.bl_legal_person"
            placeholder="请输入法定代表人"
            style="width:300px"
          />
        </el-form-item>
        
        <el-form-item label="成立时间:" prop="bl_established_date">
          <el-date-picker
            v-model="busForm.bl_established_date"
            type="date" style="width:300px"
            value-format="YYYY-MM-DD"
            placeholder="选择日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="营业时间:" prop="bl_operating_period">
          <el-date-picker style="width:300px" 
            v-model="busForm.bl_operating_period"
            type="date"
            value-format="YYYY-MM-DD"
            placeholder="选择日期(长期不选)"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="住所：" prop="bl_residence">
          <el-input 
            v-model="busForm.bl_residence"
            placeholder="请输入住所"
            style="width:300px"
          />
        </el-form-item>
        <el-form-item label="经营范围：" prop="bl_business_scope">
          <el-input
            type="textarea"
            v-model="busForm.bl_business_scope"
            placeholder="请输入经营范围"
            style="width:300px"
          />
        </el-form-item>
        <el-form-item label="上传证件:" prop="bl_imageIds">
          <div class="flex flex_warp">
              <div class="picture-card-img" v-for="(item,index) in imgs2" :key="index">
                  <img :src="$http+item.url" class="picture-card-imgs" alt="">
                  <div class="op_btns"><i @click.stop="PicturePreview({url:$http+item.url})"
                          class="el-icon-zoom-in"></i><i @click.stop="handleUploadRemove(index)"
                          class="el-icon-delete"></i></div>
              </div>
              <SigleUpload accept="image/*" type="picture-card" @PicturePreview="PicturePreview"
                  @handleUploadSuccess="handleUploadSuccess"></SigleUpload>
          </div>
        </el-form-item>
      </el-form>
    </div>
    <!-- 机构 -->
    <div class="el_form" v-if="active == 3">
        
      <el-form ref="orgForm" :rules="orgFormrules" :model="orgForm" label-width="120px">
        <el-form-item label="机构名称：" prop="mi_institution_name">
          <el-input
            v-model="orgForm.mi_institution_name"
            placeholder="请输入机构名称"
            style="width:300px"
          />
        </el-form-item>
        <el-form-item label="地址：" prop="mi_address">
          <el-input
            v-model="orgForm.mi_address"
            placeholder="请输入地址"
            style="width:300px"
          />
        </el-form-item>
        <el-form-item label="法定代表人：" prop="mi_legal_person">
          <el-input
            v-model="orgForm.mi_legal_person"
            placeholder="请输入法定代表人"
            style="width:300px"
          />
        </el-form-item>
        <el-form-item label="主要负责人：" prop="mi_main_incharge">
          <el-input
            v-model="orgForm.mi_main_incharge"
            placeholder="请输入主要负责人"
            style="width:300px"
          />
        </el-form-item>
        <el-form-item label="登记号：" prop="mi_license_number">
          <el-input
            v-model="orgForm.mi_license_number"
            placeholder="请输入登记号"
            style="width:300px"
          />
        </el-form-item>
        <el-form-item label="有效期时间:" prop="time">
          <el-date-picker
            v-model="orgForm.time"
            type="daterange"  style="width:300px"
            range-separator="至" value-format="YYYY-MM-DD"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item label="诊疗科目：" prop="mi_treatment_subjects">
          <el-input
            type="textarea"
            v-model="orgForm.mi_treatment_subjects"
            placeholder="请输入诊疗科目"
            style="width:300px"
          />
        </el-form-item>
        <el-form-item label="上传证件:" prop="mi_imageIds">
          <div class="flex flex_warp">
              <div class="picture-card-img" v-for="(item,index) in imgs3" :key="index">
                  <img :src="$http+item.url" class="picture-card-imgs" alt="">
                  <div class="op_btns"><i @click.stop="PicturePreview({url:$http+item.url})"
                          class="el-icon-zoom-in"></i><i @click.stop="handleUploadRemove(index)"
                          class="el-icon-delete"></i></div>
              </div>
              <SigleUpload accept="image/*" type="picture-card" @PicturePreview="PicturePreview"
                  @handleUploadSuccess="handleUploadSuccess"></SigleUpload>
          </div>
        </el-form-item>
      </el-form>
    </div>
    <!-- 医疗器械经营许可证 -->
    <div class="el_form" v-if="active == 4">
        
      <el-form ref="insForm" :rules="insFormrules" :model="insForm" label-width="120px">
        <el-form-item label="企业名称：" prop="mebl_company_name">
          <el-input
            v-model="insForm.mebl_company_name"
            placeholder="请输入企业名称"
            style="width:300px"
          />
        </el-form-item>
        <el-form-item label="许可证编号：" prop="mebl_permit_number">
          <el-input
            v-model="insForm.mebl_permit_number"
            placeholder="请输入许可证编号"
            style="width:300px"
          />
        </el-form-item>
        <el-form-item label="发证日期:" prop="mebl_issue_date">
          <el-date-picker
            v-model="insForm.mebl_issue_date"
            type="date" style="width:300px"
            value-format="YYYY-MM-DD"
            placeholder="选择日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="有效期时间:" prop="time">
          <el-date-picker
            v-model="insForm.time"
            type="daterange" style="width:300px"
            range-separator="至" value-format="YYYY-MM-DD"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        
        <el-form-item label="经营方式：" prop="mebl_operation_mode">
          <el-input
            v-model="insForm.mebl_operation_mode"
            placeholder="请输入经营方式"
            style="width:300px"
          />
        </el-form-item>
        <el-form-item label="法定代表人：" prop="mebl_legal_person">
          <el-input
            v-model="insForm.mebl_legal_person"
            placeholder="请输入法定代表人"
            style="width:300px"
          />
        </el-form-item>
        <el-form-item label="企业代表人：" prop="mebl_ceo">
          <el-input
            v-model="insForm.mebl_ceo"
            placeholder="请输入企业代表人"
            style="width:300px"
          />
        </el-form-item>
        <el-form-item label="经营场所：" prop="mebl_business_premises">
          <el-input
            v-model="insForm.mebl_business_premises"
            placeholder="请输入经营场所"
            style="width:300px"
          />
        </el-form-item>
        <el-form-item label="库房地址：" prop="mebl_warehouse_address">
          <el-input
            v-model="insForm.mebl_warehouse_address"
            placeholder="请输入库房地址"
            style="width:300px"
          />
        </el-form-item>
        <el-form-item label="住所：" prop="mebl_residence">
          <el-input
            v-model="insForm.mebl_residence"
            placeholder="请输入住所"
            style="width:300px"
          />
        </el-form-item>
        <el-form-item label="经营范围：" prop="mebl_business_scope">
          <el-input
            type="textarea"
            v-model="insForm.mebl_business_scope"
            placeholder="请输入经营范围"
            style="width:300px"
          />
        </el-form-item>
        <el-form-item label="上传证件:" prop="mebl_imageIds">
          <div class="flex flex_warp">
              <div class="picture-card-img" v-for="(item,index) in imgs4" :key="index">
                  <img :src="$http+item.url" class="picture-card-imgs" alt="">
                  <div class="op_btns"><i @click.stop="PicturePreview({url:$http+item.url})"
                          class="el-icon-zoom-in"></i><i @click.stop="handleUploadRemove(index)"
                          class="el-icon-delete"></i></div>
              </div>
              <SigleUpload accept="image/*" type="picture-card" @PicturePreview="PicturePreview"
                  @handleUploadSuccess="handleUploadSuccess"></SigleUpload>
          </div>
        </el-form-item>
      </el-form>
    </div>
    <!-- 第二类医疗器械经营 -->
    <div class="el_form" v-if="active == 5">
      <el-form ref="medForm" :rules="medFormrules" :model="medForm" label-width="120px">
        <el-form-item label="企业名称：" prop="smdorc_company_name">
          <el-input
            v-model="medForm.smdorc_company_name"
            placeholder="请输入企业名称"
            style="width:300px"
          />
        </el-form-item>
        <el-form-item label="备案号：" prop="smdorc_filing_number">
          <el-input
            v-model="medForm.smdorc_filing_number"
            placeholder="请输入备案号"
            style="width:300px"
          />
        </el-form-item>

        <el-form-item label="经营方式：" prop="smdorc_operation_mode">
          <el-input
            v-model="medForm.smdorc_operation_mode"
            placeholder="请输入经营方式"
            style="width:300px"
          />
        </el-form-item>
        <el-form-item label="法定代表人：" prop="smdorc_legal_person">
          <el-input
            v-model="medForm.smdorc_legal_person"
            placeholder="请输入法定代表人"
            style="width:300px"
          />
        </el-form-item>
        <el-form-item label="企业负责人：" prop="smdorc_ceo">
          <el-input
            v-model="medForm.smdorc_ceo"
            placeholder="请输入企业负责人"
            style="width:300px"
          />
        </el-form-item>
        <el-form-item label="经营场所：" prop="smdorc_business_premises">
          <el-input
            v-model="medForm.smdorc_business_premises"
            placeholder="请输入经营场所"
            style="width:300px"
          />
        </el-form-item>
        <el-form-item label="住所：" prop="smdorc_residence">
          <el-input
            v-model="medForm.smdorc_residence"
            placeholder="请输入住所"
            style="width:300px"
          />
        </el-form-item>
        
        <el-form-item label="库房地址：" prop="smdorc_warehouse_address">
          <el-input
            v-model="medForm.smdorc_warehouse_address"
            placeholder="请输入库房地址"
            style="width:300px"
          />
        </el-form-item>
        <el-form-item label="经营范围：" prop="smdorc_business_scope">
          <el-input
            type="textarea"
            v-model="medForm.smdorc_business_scope"
            placeholder="请输入经营范围"
            style="width:300px"
          />
        </el-form-item>
        <el-form-item label="上传证件:" prop="smdorc_imageIds">
          <div class="flex flex_warp">
              <div class="picture-card-img" v-for="(item,index) in imgs5" :key="index">
                  <img :src="$http+item.url" class="picture-card-imgs" alt="">
                  <div class="op_btns"><i @click.stop="PicturePreview({url:$http+item.url})"
                          class="el-icon-zoom-in"></i><i @click.stop="handleUploadRemove(index)"
                          class="el-icon-delete"></i></div>
              </div>
              <SigleUpload accept="image/*" type="picture-card" @PicturePreview="PicturePreview"
                  @handleUploadSuccess="handleUploadSuccess"></SigleUpload>
          </div>
        </el-form-item>
      </el-form>
    </div>
  </div>
  <div class="bottom_btn">
    <el-button type="primary" v-if="active !== 1" @click="upHandle"
      >上一步</el-button
    >
    <el-button type="primary" v-if="active !== 5" @click="activeHandle"
      >下一步</el-button
    >
    <el-button type="primary" v-if="active == 5" @click="activeHandle" :loading="is_ajax" 
      >提交</el-button
    >
    <el-button @click="$router.go(-1)">取消</el-button>
  </div>
  
  <!-- 上传图片预览 -->
  <el-dialog :close-on-click-modal="false" title="图片预览" width="600px" v-model="uploadImgVisible">
      <img style="width: 100%;" :src="uploadImageUrl" alt="">
  </el-dialog>
</template>

<script>
// 上传组件
    import SigleUpload from "@/components/sigleUpload";
export default {
  name: "addCustomer",
  components: {
      SigleUpload
  },
  data() {
    return {
      id: '',
      is_ajax: false,
      active: 1,
      // 基本信息
      basicForm: {
        is_sales:0,
        is_purchase:0
      },
      // 营业执照
      busForm: {
        
      },
      //机构
      orgForm: {
        time:''
      },
      //器械
      insForm: {
        time:''
      },
      //第二类医疗
      medForm: {
      },
      dialogImageUrl: "",
      dialogVisible: false,
      
      basicFormrules: {
          merchant_name: [
            { required: true, message: "客商名称不能为空",trigger: "blur"}
          ],
          // merchant_no: [
          //   { required: true, message: "客商编号前缀不能为空",trigger: "blur"}
          // ],
          // mnemonic_code: [
          //   { required: true, message: "助记码不能为空",trigger: "blur"}
          // ],
      },
      busFormrules: {
          // bl_code: [
          //   { required: true, message: "统一社会信用代码不能为空",trigger: "blur"}
          // ],
          // bl_name: [
          //   { required: true, message: "名称不能为空",trigger: "blur"}
          // ],
          // bl_legal_person: [
          //   { required: true, message: "法定代表人不能为空",trigger: "blur"}
          // ],
          // bl_established_date: [
          //   { required: true, message: "成立时间不能为空",trigger: "blur"}
          // ],
          // bl_residence: [
          //   { required: true, message: "住所不能为空",trigger: "blur"}
          // ],
          // bl_business_scope: [
          //   { required: true, message: "经营范围不能为空",trigger: "blur"}
          // ],
          // bl_imageIds: [
          //   { required: true, message: "证件图片不能为空",trigger: "blur"}
          // ],
      },
      orgFormrules: {
          // mi_institution_name: [
          //   { required: true, message: "机构名称不能为空",trigger: "blur"}
          // ],
          // mi_address: [
          //   { required: true, message: "住所不能为空",trigger: "blur"}
          // ],
          // mi_legal_person: [
          //   { required: true, message: "法定代表人不能为空",trigger: "blur"}
          // ],
          // mi_main_incharge: [
          //   { required: true, message: "主要负责人不能为空",trigger: "blur"}
          // ],
          // mi_license_number: [
          //   { required: true, message: "登记号不能为空",trigger: "blur"}
          // ],
          // time: [
          //   { required: true, message: "有效期不能为空",trigger: "blur"}
          // ],
          // mi_treatment_subjects: [
          //   { required: true, message: "诊疗科目不能为空",trigger: "blur"}
          // ],
          // mi_imageIds: [
          //   { required: true, message: "证件图片不能为空",trigger: "blur"}
          // ],
      },
      insFormrules: {
          // mebl_company_name: [
          //   { required: true, message: "企业名称不能为空",trigger: "blur"}
          // ],
          // mebl_permit_number: [
          //   { required: true, message: "许可证编号不能为空",trigger: "blur"}
          // ],
          // mebl_issue_date: [
          //   { required: true, message: "发证日期不能为空",trigger: "blur"}
          // ],
          // mebl_operation_mode: [
          //   { required: true, message: "经营方式不能为空",trigger: "blur"}
          // ],
          // mebl_legal_person: [
          //   { required: true, message: "法定代表人不能为空",trigger: "blur"}
          // ],
          // time: [
          //   { required: true, message: "有效期不能为空",trigger: "blur"}
          // ],
          // mebl_ceo: [
          //   { required: true, message: "企业代表人不能为空",trigger: "blur"}
          // ],
          // mebl_business_premises: [
          //   { required: true, message: "经营场所不能为空",trigger: "blur"}
          // ],
          // mebl_residence: [
          //   { required: true, message: "住所不能为空",trigger: "blur"}
          // ],
          // mebl_warehouse_address: [
          //   { required: true, message: "库房地址不能为空",trigger: "blur"}
          // ],
          // mebl_business_scope: [
          //   { required: true, message: "经营范围不能为空",trigger: "blur"}
          // ],
          // mebl_imageIds: [
          //   { required: true, message: "证件图片不能为空",trigger: "blur"}
          // ],
      },
      medFormrules: {
          // smdorc_company_name: [
          //   { required: true, message: "企业名称不能为空",trigger: "blur"}
          // ],
          // smdorc_legal_person: [
          //   { required: true, message: "法定代表人不能为空",trigger: "blur"}
          // ],
          // smdorc_filing_number: [
          //   { required: true, message: "备案号不能为空",trigger: "blur"}
          // ],
          // smdorc_ceo: [
          //   { required: true, message: "企业负责人不能为空",trigger: "blur"}
          // ],
          // smdorc_operation_mode: [
          //   { required: true, message: "经营方式不能为空",trigger: "blur"}
          // ],
          // smdorc_business_premises: [
          //   { required: true, message: "经营场所不能为空",trigger: "blur"}
          // ],
          // smdorc_residence: [
          //   { required: true, message: "住所不能为空",trigger: "blur"}
          // ],
          // smdorc_warehouse_address: [
          //   { required: true, message: "库房地址不能为空",trigger: "blur"}
          // ],
          // smdorc_business_scope: [
          //   { required: true, message: "经营范围不能为空",trigger: "blur"}
          // ],
          // smdorc_imageIds: [
          //   { required: true, message: "备案图片不能为空",trigger: "blur"}
          // ],
      },
      zjlist:[],
      // 上传图片
      uploadImageUrl: '',
      uploadImgVisible: false,
      imgs2: [],
      imgs3: [],
      imgs4: [],
      imgs5: [],
      forms:['basicForm','busForm','orgForm','insForm','medForm'],
      imageIds:['','bl_imageIds','mi_imageIds','mebl_imageIds','smdorc_imageIds']
    };
  },
  created() {
      this.id=this.$route.query.id || '';
      if(this.id){
          // this.basicFormrules.merchant_no[0].required = false;
          this.get_data()
      }
      this.getOptions();
  },
  methods: {
      input_blur(){
          if(this.basicForm.merchant_name){
            this.$httpGet("/backend/product.Product/getMnemonicCode", {
                product_name: this.basicForm.merchant_name
            }).then((res) => {
                if (res.status == 200) {
                    this.basicForm.mnemonic_code=res.data
                } else {
                    this.$message.error(res.message);
                }
            }).catch((err) => {
                console.log(err);
            });
          }else{
            this.basicForm.mnemonic_code=''
          }
      },
      // 上传图片--s
      PicturePreview(file) {
          this.uploadImageUrl = file.url;
          this.uploadImgVisible = true;
      },
      handleUploadSuccess(res) {
          this['imgs'+this.active].push(res);
          this.setimageIds()
      },
      handleUploadRemove(index) {
          this['imgs'+this.active].splice(index, 1);
          this.setimageIds()
      },
      setimageIds() {
          let ids = [];
          for (let i = 0; i < this['imgs'+this.active].length; i++) {
              ids.push(this['imgs'+this.active][i].id)
          }
          let form=this.forms[this.active-1]
          let imageIds=this.imageIds[this.active-1]
          this[form][imageIds] = ids.join(',')
      },
    upHandle() {
      this.active--;
    },
    reset(pid) {
        this.basicForm={is_sales:0,is_purchase:0,merchant_no:this.zjlist.length>0?this.zjlist[0].value:''};
        this.busForm={};
        this.orgForm={time:''};
        this.insForm={time:''};
        this.medForm={};
        this.imgs2 = [];
        this.imgs3 = [];
        this.imgs4 = [];
        this.imgs5 = [];
        if (this.$refs['basicForm']) {
            this.$refs['basicForm'].clearValidate();
        }
    },
    activeHandle() {
        let form=this.forms[this.active-1];
        this.$refs[form].validate((valid) => {
            if (valid) {
                if(this.active==5){
                    // 提交
                    this.is_ajax = true;
                    // 时间范围
                    this.orgForm.mi_valid_from=this.orgForm.time[0];
                    this.orgForm.mi_valid_until=this.orgForm.time[1];
                    this.insForm.mebl_issue_date=this.insForm.time[0];
                    this.insForm.mebl_valid_until=this.insForm.time[1];
                    
                    let _data={...this.basicForm,...this.busForm,...this.orgForm,...this.insForm,...this.medForm};
                    if(this.id){
                        _data.id=this.id
                    }
                    this.$httpPost("/backend/Merchant/save", _data).then((res) => {
                        if (res.status == 200) {
                            this.$message.success(this.id ? '更新成功' : '新增成功');
                            if(this.id){
                                this.$router.go(-1)
                            }else{
                                // this.active=1;
                                // this.$nextTick(()=>{
                                //     this.reset();
                                // })
                                this.$router.go(-1)
                            }
                        } else {
                            this.$message.error(res.message);
                        }
                        this.is_ajax = false;
                    }).catch((err) => {
                        console.log(err);
                        this.is_ajax = false;
                    });
                }else{
                   this.active++; 
                }
            }
        })
    },
    get_data(){
        this.$httpGet("/backend/Merchant/read", {
            merchant_id: this.id
        }).then((res) => {
            if (res.status == 200) {
                let _data = res.data;
                // -----
                this.basicForm={
                    merchant_name:_data.merchant_name,
                    merchant_no:_data.merchant_no,
                    is_purchase:_data.is_purchase,
                    is_sales:_data.is_sales,
                    mnemonic_code:_data.mnemonic_code
                }
                
                for(let item in _data){
                    if(item.split('_')[0]=='bl'){
                        this.busForm[item]=_data[item]
                    }
                    if(item.split('_')[0]=='mi'){
                        this.orgForm[item]=_data[item]
                    }
                    if(item.split('_')[0]=='mebl'){
                        this.insForm[item]=_data[item]
                    }
                    if(item.split('_')[0]=='smdorc'){
                        this.medForm[item]=_data[item]
                    }
                }
                // 时间范围还原
                this.orgForm.time=[_data.mi_valid_from,_data.mi_valid_until]
                this.insForm.time=[_data.mebl_issue_date,_data.mebl_valid_until]
                // 图片还原
                let merchant_image=_data.merchant_image || [];
                for(let i=0;i<merchant_image.length;i++){
                    let type=merchant_image[i].type+1
                    this['imgs'+type].push({
                        id:merchant_image[i].file_id,
                        url: '/storage/' + merchant_image[i].save_path.split('/storage/')[1]
                    })
                }
                for(let k=2;k<6;k++){
                    let ids = [];
                    for (let i = 0; i < this['imgs'+k].length; i++) {
                        ids.push(this['imgs'+k][i].id)
                    }
                    let form=this.forms[k-1]
                    let imageIds=this.imageIds[k-1]
                    this[form][imageIds] = ids.join(',')
                }
                
            } else {
                this.$message.error(res.message);
            }
        }).catch((err) => {
            console.log(err);
        });
    },
    getOptions(){
        this.$httpGet("/backend/SysConfig/index", {
            size: 10000000,
            groups: '供应商编号'
        }).then((res) => {
            if (res.status == 200) {
                this.zjlist = res.data.data;
                if(!this.id && this.zjlist.length>0){
                    this.basicForm.merchant_no=this.zjlist[0].value
                }
            } else {
                this.$message.error(res.message);
            }
        }).catch((err) => {
            console.log(err);
        });
    }
  },
};
</script>

<style lang="scss" scoped>
// #1676E0
.el_form {
  padding-left: 190px;
  padding-right: 60px;
  padding-bottom: 30px;
  margin-top: 30px;
}
::v-deep .el-step.is-center .el-step__description {
  margin-top: 10px;
  font-size: 14px;
  font-weight: 500;
  // color: #323233;
}
::v-deep .el-upload-list--picture-card .el-upload-list__item {
  width: 80px;
  height: 80px;
}
::v-deep .el-upload--picture-card {
  width: 80px;
  height: 80px;
  line-height: 80px;
}
</style>
